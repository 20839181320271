var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dense":"","color":"grey lighten-2"}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.title)+" tarefa "),(_vm.taskId)?_c('span',{staticClass:"body-2"},[_c('i',[_vm._v("#"+_vm._s(_vm.task.id))])]):_vm._e()]),_c('v-spacer'),_c('v-toolbar-items',[(_vm.loading)?_c('v-progress-linear',{attrs:{"color":"primary","absolute":"","bottom":"","indeterminate":""}}):_vm._e(),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.show = false}}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-close')}})],1)],1)],1),_c('v-card-text',{staticClass:"pt-4 text-center"},[_c('validation-observer',{ref:"observer"},[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"customerId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.customers,"loading":_vm.loadingCustomers,"item-value":"id","item-text":"companyName","label":"Cliente","hide-details":"auto","error-messages":errors},model:{value:(_vm.task.customerId),callback:function ($$v) {_vm.$set(_vm.task, "customerId", $$v)},expression:"task.customerId"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"deliveryDeadlineDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-date',{attrs:{"label":"Data de vencimento","error-messages":errors},model:{value:(_vm.task.deliveryDeadlineDate),callback:function ($$v) {_vm.$set(_vm.task, "deliveryDeadlineDate", $$v)},expression:"task.deliveryDeadlineDate"}})]}}])})],1),_c('v-col',[_c('validation-provider',{attrs:{"vid":"alertDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-date',{attrs:{"label":"Data de alerta","error-messages":errors},model:{value:(_vm.task.alertDate),callback:function ($$v) {_vm.$set(_vm.task, "alertDate", $$v)},expression:"task.alertDate"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Título","error-messages":errors},model:{value:(_vm.task.title),callback:function ($$v) {_vm.$set(_vm.task, "title", $$v)},expression:"task.title"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"hide-details":"auto","label":"Descrição","error-messages":errors},model:{value:(_vm.task.description),callback:function ($$v) {_vm.$set(_vm.task, "description", $$v)},expression:"task.description"}})]}}])})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),(!_vm.loading)?_c('v-btn',{attrs:{"color":"success","outlined":"","loading":_vm.loadingSave},on:{"click":_vm.save}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('mdi-content-save')}}),_vm._v(" Salvar ")],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }