<template>
  <v-dialog v-model="show" max-width="700px">
    <v-card>
      <v-toolbar flat dense color="grey lighten-2">
        <v-toolbar-title>
          {{ title }} tarefa
          <span v-if="taskId" class="body-2">
            <i>#{{ task.id }}</i>
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-progress-linear
            v-if="loading"
            color="primary"
            absolute
            bottom
            indeterminate
          />
          <v-btn icon small @click="show = false">
            <v-icon v-text="'mdi-close'" />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <validation-observer ref="observer">
          <v-row>
            <v-col>
              <validation-provider v-slot="{ errors }" vid="customerId">
                <v-autocomplete
                  v-model="task.customerId"
                  :items="customers"
                  :loading="loadingCustomers"
                  item-value="id"
                  item-text="companyName"
                  label="Cliente"
                  hide-details="auto"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider v-slot="{ errors }" vid="deliveryDeadlineDate">
                <input-date
                  v-model="task.deliveryDeadlineDate"
                  label="Data de vencimento"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col>
              <validation-provider v-slot="{ errors }" vid="alertDate">
                <input-date
                  v-model="task.alertDate"
                  label="Data de alerta"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider v-slot="{ errors }" vid="title">
                <v-text-field
                  v-model="task.title"
                  hide-details="auto"
                  label="Título"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider v-slot="{ errors }" vid="description">
                <v-textarea
                  v-model="task.description"
                  hide-details="auto"
                  label="Descrição"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!loading"
          color="success"
          outlined
          :loading="loadingSave"
          @click="save"
        >
          <v-icon v-text="'mdi-content-save'" left />
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import customerTasksApi from '@/api/customer-tasks'
import customersApi from '@/api/customers'

export default {
  components: {
    InputDate: () => import('@/components/input/InputDate'),
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    taskId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      loading: false,
      loadingSave: false,
      loadingCustomers: false,
      customers: [],
      task: {},
    }
  },

  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },

    title() {
      return this.taskId ? 'Editar' : 'Adicionar'
    },
  },

  watch: {
    show(val) {
      if (!val) return

      this.load()
      this.loadCustomers()
    },
  },

  methods: {
    async load() {
      try {
        this.task = {}

        if (!this.taskId) {
          return
        }

        this.loading = true

        const response = await customerTasksApi.get(this.taskId)

        this.task = response.data.task
      } catch (e) {
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loading = false
      }
    },

    async save() {
      try {
        this.loadingSave = true
        this.$refs.observer.reset()

        if (this.taskId) {
          await customerTasksApi.update(this.taskId, this.task)
        } else {
          await customerTasksApi.insert(this.task)
        }

        this.show = false
        this.$snackbar.show({
          color: 'success',
          message: this.taskId
            ? this.$messages._('update_success')
            : this.$messages._('new_success'),
        })
        this.$emit('save')
      } catch (e) {
        if (e.response.status === 422) {
          this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
          return
        }

        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loadingSave = false
      }
    },

    async loadCustomers() {
      try {
        this.customers = []
        this.loadingCustomers = true
        const response = await customersApi.list()
        this.customers = response.data.customers
      } catch (e) {
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loadingCustomers = false
      }
    },
  },
}
</script>
